import React from 'react';
import {
  required,
  SimpleForm,
  Edit,
  SelectInput,
  NumberInput,
  BooleanInput,
  useTranslate,
  number,
  minValue,
} from 'react-admin';
import Tooltip from '@mui/material/Tooltip';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const sortOptionsChoices = [
  { name: 'NEAREST', id: 'nearest' },
  { name: 'FASTEST', id: 'fastest' },
];
const validateRequired = required();
const validateMinNumber = (min = 0) => [required(), number(), minValue(min)];

const NumberInputWrapper = React.forwardRef((props, ref) => {
  //  Spread the props to the underlying DOM element.
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
});

const CourierAutomationSettingsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <BooleanInput label="ra.action.enable" source="enable" />
        <Tooltip
          title={`${translate('ra.label.maxDistanceNearPickUpLocation')} km`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              label={`${translate('ra.label.maxDistanceNearPickUpLocation')} km`}
              source="maxDistanceNearPickUpLocation"
              validate={validateMinNumber()}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.maxDistanceNearDestination')} km`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="maxDistanceNearDestination"
              label={`${translate('ra.label.maxDistanceNearDestination')} km`}
              validate={validateMinNumber()}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.maxDistanceCoincidentalOrders')} km`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="maxDistanceCoincidentalOrders"
              label={`${translate('ra.label.maxDistanceCoincidentalOrders')} km`}
              validate={validateMinNumber()}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.maxOrdersPerCourier')} km`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="maxOrdersPerCourier"
              label={translate('ra.label.maxOrdersPerCourier')}
              validate={validateMinNumber(1)}
            />
          </NumberInputWrapper>
        </Tooltip>
        {/* Did not delete these fields as these should be eventually returned back, as soon as the issue with values configuration is solved */}
        {/* <Tooltip
          title={`${translate('ra.label.retryTime')} sec.`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="retryTime"
              label={`${translate('ra.label.retryTime')} sec.`}
              validate={validateRequired}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.timeAwaitingCourierConfirmation')} sec.`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="timeAwaitingCourierConfirmation"
              label={`${translate('ra.label.timeAwaitingCourierConfirmation')} sec.`}
              validate={validateRequired}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip> */}
        <Tooltip
          title={`${translate('ra.label.timeIgnoreCourier')} sec.`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="timeIgnoreCourier"
              label={`${translate('ra.label.timeIgnoreCourier')} sec.`}
              validate={validateMinNumber()}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.multiplierModifier')} 1`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="multiplierModifier"
              label={`${translate('ra.label.multiplierModifier')} 1`}
              validate={validateMinNumber()}
            />
          </NumberInputWrapper>
        </Tooltip>
        <Tooltip
          title={`${translate('ra.label.intervalGetCourierLocation')} sec.`}
          placement="top"
          arrow
        >
          <NumberInputWrapper>
            <NumberInput
              source="intervalGetCourierLocation"
              label={`${translate('ra.label.intervalGetCourierLocation')} sec.`}
              validate={validateMinNumber()}
              parse={(v) => v * 1000 || ''}
              format={(v) => v / 1000 || ''}
            />
          </NumberInputWrapper>
        </Tooltip>
        <SelectInput
          label="ra.label.sortOptions"
          source="sortOptions"
          choices={sortOptionsChoices}
          validate={validateRequired}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CourierAutomationSettingsEdit;
