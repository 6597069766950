import React from 'react';
import {
  useGetResourceLabel,
  Pagination,
  ListBase,
  Title,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';
import { Box } from '@mui/material';
import ImageGrid from '../common/ImageGrid';

const CreateRequestList = () => {
  const organizationId = localStorage.getItem('organizationId');
  const getResourceLabel = useGetResourceLabel();

  return (
    <ListBase filter={{ organizationId }} perPage={20}>
      <Box>
        <TopToolbar>
          <CreateButton />
          <ExportButton />
        </TopToolbar>
      </Box>
      <Title defaultTitle={getResourceLabel('products', 2)} />
      <Box sx={{ flexGrow: 1 }}>
        <ImageGrid />
      </Box>
      <Pagination rowsPerPageOptions={[20, 30, 50]} />

    </ListBase>
  );
};

export default CreateRequestList;
