import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import CancelButton from '../../../components/CancelButton';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';

const validateRequired = required();

const CourierCreate = (props) => {
  return (
    <Create
      {...props}
      title={<ResourceTitle field="firstName" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list">
        <PhoneInput source="phone" shouldValidate />
        <TextInput label="ra.label.first_name" source="firstName" validate={validateRequired} />
        <TextInput label="ra.label.last_name" source="lastName" validate={validateRequired} />
      </SimpleForm>
    </Create>
  );
};

export default CourierCreate;
