export const PAYMENT_METHODS = {
  CREDIT_CARD: 'credit-card',
  CASH: 'cash',
  CREDIT_CARD_ONLINE: 'credit-card-online',
};

export const PAYMENT_METHODS_LABELS = {
  'credit-card': 'Credit card',
  cash: 'Cash',
  'credit-card-online': 'Credit card online',
};

export const SHOW_SERIAL_NUMBERS = true;

export const DEFAULT_PAYMENT_METHODS = {
  [PAYMENT_METHODS.CREDIT_CARD]: true,
  [PAYMENT_METHODS.CASH]: true,
  [PAYMENT_METHODS.CREDIT_CARD_ONLINE]: true,
};

export default PAYMENT_METHODS;
