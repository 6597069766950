import React, { useEffect, useState } from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  ImageField,
  Show,
  Tab,
  TabbedShowLayout,
  useRefresh,
  useShowController,
  useTranslate,
} from 'react-admin';
import { connect } from 'react-redux';
import { io } from 'socket.io-client';
import { fromEvent, asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import PriceField from '../../../components/PriceField';
import url, { baseUrl } from '../../../config/connection';
import { initSocket } from '../../../redux/socket';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';
import { getConfig } from '../../../utils/getInitialConfig';
import ShowActions from '../Actions';
import OrderModals from '../OrderModals';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { SHOW_SERIAL_NUMBERS } from '../../../config/payment.config';

const throttleConfig = {
  leading: true,
  trailing: true,
};

const socketIO = io(baseUrl, { transports: ['websocket'], rejectUnauthorized: false });

const OrderShow = ({ createSocket, socket, ...props }) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const [courier, setCourier] = useState(null);

  const { record } = useShowController();

  const { deliveryDetails, deliveryUserId, fiscalCheck } = record;

  useEffect(() => {
    if (!courier && deliveryUserId) {
      fetchWithAuthorization(`${url}/courier/uid/${deliveryUserId}`)
        .then((results) => results.json())
        .then((data) => setCourier(data));
    }
  }, [deliveryUserId, courier]);

  useEffect(() => {
    if (!socket) {
      createSocket(socketIO);
    } else {
      socketIO.connect();

      const updateCourierEvent = fromEvent(socketIO, 'updatedOrderList').pipe(
        throttleTime(10 * 1000, asyncScheduler, throttleConfig),
      );

      const updateCourierEventSubscription = updateCourierEvent.subscribe(() => {
        refresh();
        fetchWithAuthorization(`${url}/courier/uid/${deliveryUserId}`)
          .then((results) => results.json())
          .then((data) => setCourier(data));
      });

      return () => {
        updateCourierEventSubscription.unsubscribe();
        socketIO.disconnect();
      };
    }
  }, [socket, createSocket, refresh, deliveryUserId]);

  return (
    <>
      <Show {...props} actions={<ShowActions {...props} />}>
        <TabbedShowLayout>
          <Tab label="ra.tab.summary">
            <FunctionField
              label="ra.label.address"
              render={({ deliveryAddress }) => {
                return `${deliveryAddress.country},
                  ${deliveryAddress.locality},
                  ${deliveryAddress.street},
                  ${deliveryAddress.buildingNumber},
                  ${translate('ra.title.floor')}: ${deliveryAddress.floor},
                  ${translate('ra.title.apartment')}: ${deliveryAddress.apartment}`;
              }}
            />
            <FunctionField
              label="ra.label.order_instructions"
              render={({ deliveryAddress }) => `${deliveryAddress?.extraInfo}`}
            />
            <FunctionField
              label="ra.label.client"
              render={({ customer }) => {
                return `${customer.firstName} ${customer.lastName}, ${formatPhoneNumber(
                  customer.phoneNumber,
                )}`;
              }}
            />

            <FunctionField
              label="ra.label.courier"
              render={() => {
                return (
                  courier &&
                  `${courier.firstName} ${courier.lastName}, ${formatPhoneNumber(courier.phone)}`
                );
              }}
            />
            <ArrayField label="ra.title.order_items" source="products">
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  label="ra.label.name"
                  render={(products) => `${products.quantity}x ${products.name}`}
                />
                <PriceField
                  label="ra.label.price"
                  source="totalPrice"
                  parse={(v) => v / 100}
                  options={{
                    style: 'currency',
                    currency: getConfig('currency'),
                  }}
                />
                {SHOW_SERIAL_NUMBERS && (
                  <FunctionField
                    label="ra.label.serial_numbers"
                    render={({ serialNumbers }) =>
                      serialNumbers?.map((sn) => (
                        <p>
                          {sn}
                          <br />
                        </p>
                      ))}
                  />
                )}
              </Datagrid>
            </ArrayField>
            <FunctionField
              label="ra.title.payment_method"
              render={({ paymentMethod }) => `${paymentMethod}`}
            />
            <PriceField
              label="ra.title.total_price"
              source="totalSum"
              parse={(v) => v / 100}
              options={{
                style: 'currency',
                currency: getConfig('currency'),
              }}
            />
            {deliveryDetails && deliveryDetails.courierCost && (
              <PriceField
                label="ra.title.courier_cost"
                source="deliveryDetails"
                parse={(v) => v.courierCost / 100}
                options={{
                  style: 'currency',
                  currency: getConfig('currency'),
                }}
              />
            )}
            {fiscalCheck && (
              <ImageField
                sx={{
                  width: 350,
                }}
                label="ra.label.fiscal_check"
                source="fiscalCheck"
              />
            )}
          </Tab>
        </TabbedShowLayout>
      </Show>

      <OrderModals />
    </>
  );
};

const mapStateToProps = (state) => ({
  socket: state.socket.client,
});

const mapDispatchToProps = (dispatch) => ({
  createSocket: (socket) => dispatch(initSocket(socket)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderShow);
