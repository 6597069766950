import { Input } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import usePlacesAutocomplete from 'use-places-autocomplete';

const PlacesAutocomplete = ({ onAddressSelect, addressActive }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 500,
    cache: 86400,
  });
  const t = useTranslate();

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeID,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
        description,
      } = suggestion;

      return (
        <button
          style={{
            padding: '10px',
            backgroundColor: 'whitesmoke',
            cursor: 'pointer',
            maxWidth: '500px',
            width: '70dvw',
            border: 'none',
            borderBottom: '1px solid black',
          }}
          key={placeID}
          type="button"
          onClick={() => {
            setValue(description, false);
            clearSuggestions();
            onAddressSelect(description);
          }}
        >
          <strong>{mainText}</strong>
          {' '}
          <small>{secondaryText}</small>
        </button>
      );
    });
  useEffect(() => {
    setValue(addressActive, false);
  }, [addressActive, setValue]);

  return (
    <div style={{ position: 'absolute', zIndex: '10', padding: '10px' }}>
      <Input
        sx={{
          height: '50px',
          backgroundColor: 'whitesmoke',
          padding: '10px',
          width: '70dvw',
          maxWidth: '500px',
          marginBottom: '10px',
        }}
        value={value}
        disabled={!ready}
        onChange={(e) => setValue(e.target.value)}
        placeholder={t('ra.action.add_address')}
      />
      {status === 'OK' && (
        <div style={{ listStyle: 'none', display: 'flex', flexDirection: 'column' }}>
          {renderSuggestions()}
        </div>
      )}
    </div>
  );
};
export default PlacesAutocomplete;
