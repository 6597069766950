export const paymentMethodChoices = [
  {
    name: 'CREDIT CARD',
    id: 'credit-card',
  },
  {
    name: 'CASH',
    id: 'cash',
  },
  {
    name: 'CREDIT CARD ONLINE',
    id: 'credit-card-online',
  },
];

export const paymentStatusChoices = [
  {
    name: 'SUCCESS',
    id: 'success',
  },
  {
    name: 'FAILED',
    id: 'failed',
  },
  {
    name: 'PENDING',
    id: 'pending',
  },
];

export const deliveryAddressKeys = [
  'intercom',
  'entrance',
  'floor',
  'apartment',
  'extraInfo',
];
