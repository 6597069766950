import React, { useMemo } from 'react';
import { MenuItemLink, useResourceDefinitions, useTranslate } from 'react-admin';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import DefaultIcon from '@mui/icons-material/ViewList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './styles';
import {
  DELIVERY_MANAGEMENT,
  INTEGRATIONS, MAIN_PART,
  USER_MANAGEMENT,
  DELIVERY_REQUEST } from './constants';
import { USER_ROLES } from '../../../config/user.config';

const getResourcesPart = (resources, part) => resources.filter(({ name }) => part.includes(name));

const MyMenu = ({ onMenuClick }) => {
  const styles = useStyles();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const translate = useTranslate();

  const role = localStorage.getItem('role');

  const [mainPart, deliveryManagement, userManagement, deliveryRequest, integrations] = useMemo(
    () =>
      [MAIN_PART, DELIVERY_MANAGEMENT, USER_MANAGEMENT, DELIVERY_REQUEST, INTEGRATIONS]
        .map((part) => {
          return getResourcesPart(resources, part);
        }),
    [resources],
  );

  return (
    <div>
      {mainPart.map(({ name, options, icon }) => (
        <MenuItemLink
          key={name}
          to={`/${name}`}
          primaryText={translate(options?.label || `resources.${name}.name`)}
          leftIcon={icon ? <icon /> : <DefaultIcon />}
          onClick={onMenuClick}
          className={styles.menuItem}
        />
      ))}
      {role === USER_ROLES.USER && (
        <>
          <Accordion defaultExpanded elevation={0} className={styles.subgroup}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.summary}>
              <Typography>{translate('ra.navigation.delivery_management')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.expandDetails}>
              {deliveryManagement.map(({ name, options, icon }) => (
                <MenuItemLink
                  key={name}
                  to={`/${name}`}
                  primaryText={translate(options?.label || `resources.${name}.name`)}
                  leftIcon={icon ? <icon /> : <DefaultIcon />}
                  onClick={onMenuClick}
                  className={styles.menuItem}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded elevation={0} className={styles.subgroup}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.summary}>
              <Typography>{translate('ra.navigation.delivery_request')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.expandDetails}>
              {deliveryRequest.map(({ name, options, icon }) => (
                <MenuItemLink
                  key={name}
                  to={`/${name}`}
                  primaryText={translate(options?.label || `resources.${name}.name`)}
                  leftIcon={icon ? <icon /> : <DefaultIcon />}
                  onClick={onMenuClick}
                  className={styles.menuItem}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded elevation={0} className={styles.subgroup}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.summary}>
              <Typography>{translate('ra.navigation.integrations')}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.expandDetails}>
              {integrations.map(({ name, options, icon }) => (
                <MenuItemLink
                  key={name}
                  to={`/${name}`}
                  primaryText={translate(options?.label || `resources.${name}.name`)}
                  leftIcon={icon ? <icon /> : <DefaultIcon />}
                  onClick={onMenuClick}
                  className={styles.menuItem}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {userManagement.length > 0 && role === USER_ROLES.ADMIN && (
        <Accordion defaultExpanded elevation={0} className={styles.subgroup}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.summary}>
            <Typography>{translate('ra.navigation.user_management')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.expandDetails}>
            {userManagement.map(({ name, options, icon }) => (
              <MenuItemLink
                key={name}
                to={`/${name}`}
                primaryText={translate(options?.label || `resources.${name}.name`)}
                leftIcon={icon ? <icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                className={styles.menuItem}
              />
            ))}
            {/* custom menu item */}
            <MenuItemLink
              key="report"
              to="/report"
              primaryText={translate('ra.title.reports')}
              leftIcon={<DefaultIcon />}
              onClick={onMenuClick}
              className={styles.menuItem}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default MyMenu;
