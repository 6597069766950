import React, { useEffect, useState } from 'react';
import { Card, CardMedia, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRecordContext, useTranslate } from 'react-admin';
import { ReceiptOutlined } from '@mui/icons-material';
import useStyles from './styles';
import url, { baseUrl } from '../../../config/connection';
import ShowAddressOnMap from '../ShowAddressOnMapButton';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import OrderItemsList from './OrderItemsList';
import TimeInfo from './orderInfo/TimeInfo';
import { formatPhoneNumber } from '../../../utils/parsePhoneNumber';
import { getConfig } from '../../../utils/getInitialConfig';

const OrderItems = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const {
    customer,
    paymentMethod,
    deliveryUserId,
    totalSum,
    deliveryAddress,
    products,
    paymentStatus,
    sourceAddress,
    cancelReasonText,
    deliveryDetails,
  } = record;

  const {
    locality,
    buildingNumber,
    street,
    country,
    floor,
    apartment,
    streetNumber,
    intercom,
    entrance,
    area: deliveryAddressArea,
  } = deliveryAddress;

  const {
    name,
    phone,
    locality: sourceLocality,
    area,
    house,
    street: sourceAddressStreet,
    country: sourceCountry,
  } = sourceAddress;

  let { deliveryStatusLogs } = record;

  if (!deliveryStatusLogs.length) deliveryStatusLogs = undefined;
  const courierCost =
    deliveryDetails && deliveryDetails.courierCost ? deliveryDetails.courierCost : undefined;

  const styles = useStyles();
  const timeScheduledAt = new Date(record.createdAt).toString().split(' ');
  const formatedTime = `${timeScheduledAt[2]} ${timeScheduledAt[1]} ${timeScheduledAt[4]}`;

  const [open, setOpen] = useState(false);
  const [courier, setCourier] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = 'https://www.rabrotech.com/upload/default/image-not-found.png';
    e.target.height = window.innerHeight / 2;
    e.target.width = window.innerWidth / 2;
  };

  useEffect(() => {
    if (!courier && deliveryUserId) {
      fetchWithAuthorization(`${url}/courier/uid/${deliveryUserId}`)
        .then((results) => results.json())
        .then((data) => setCourier(data));
    }
  }, [deliveryUserId, courier]);

  let paymentTextColor;
  // eslint-disable-next-line default-case
  switch (paymentStatus) {
    case 'failed':
      paymentTextColor = '#FA0064';
      break;
    case 'pending':
      paymentTextColor = '#FFAA00';
      break;
    case 'success':
      paymentTextColor = '#00A66F';
      break;
  }

  return (
    <div className={styles.dropdownWrapper}>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.address')}</div>
        <div>{`${street} ${buildingNumber || ''},`}</div>
        <div>
          {floor ? `${translate('ra.title.floor')}: ${floor}, ` : ''}
          {apartment ? `${translate('ra.title.apartment')} ${apartment}, ` : ''}
        </div>
        <div>{streetNumber ? `${translate('ra.title.street_number')}: ${streetNumber}, ` : ''}</div>
        <div>{intercom ? `${translate('ra.title.intercom')}: ${intercom}, ` : ''}</div>
        <div>{entrance ? `${translate('ra.title.entrance')}: ${entrance}, ` : ''}</div>
        <div>
          {deliveryAddressArea ? `${translate('ra.title.area')}: ${deliveryAddressArea}, ` : ''}
        </div>
        <div>{country ? `${translate('ra.title.country')}: ${country}, ` : ''}</div>
        <div>{locality ? `${translate('ra.title.locality')}: ${locality}, ` : ''}</div>
        <ShowAddressOnMap deliveryAddress={deliveryAddress} />
      </Card>
      {/* <Card className={instructionCardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.order_comment')}</div>
        <div className={styles.w200}>{`${record.instructions || ''}`}</div>
      </Card> */}
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.client_data')}</div>
        {`${customer.firstName} ${customer.lastName}`}
        <br />
        {customer.phoneNumber}
        <div>{customer.phone}</div>
      </Card>
      {/* <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.supplier_data')}</div>
        {supplier.supplierName}
        <br />
        {supplier.branchName}
        <br />
        {supplierPhone ? (
          <a href={` tel:${supplierPhone}`}>{supplierPhone}</a>
        ) : (
          <p>{translate('ra.message.no_number')}</p>
        )}
        {getIsExternalId(externalId) && (
          <>
            <br />
            {`Order ID: ${externalId.shortCode}`}
          </>
        )}
      </Card> */}
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.order_items')}</div>

        <OrderItemsList orderItems={products} />
      </Card>
      {courier && (
        <Card className={styles.cardStyle}>
          <Card className={styles.courierCarStyle}>
            <div className={styles.cardTitle}>
              <span>{translate('ra.title.block.courier_data')}</span>
              {record.fiscalCheck && (
                <IconButton onClick={handleClickOpen} size="small">
                  <ReceiptOutlined />
                </IconButton>
              )}
            </div>
            {courier && (
              <div className={styles.cardDetails}>
                {`${translate('ra.label.first_name')}: ${courier.firstName}`}
                <br />
                {`${translate('ra.label.last_name')}: ${courier.lastName}`}
                <br />
                {`${translate('ra.label.phone')}: ${formatPhoneNumber(courier.phone)}`}
                <br />
                <span>{`${translate('ra.label.ordered_at')}: ${formatedTime}`}</span>
              </div>
            )}
          </Card>
        </Card>
      )}
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <div className={styles.flexEnd}>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers>
          <CardMedia
            image={`${record.fiscalCheck}`}
            component="img"
            classes={{ root: styles.imgLighBox }}
            onError={handleImageError}
          />
        </DialogContent>
      </Dialog>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.price')}</div>
        {/* <div>
          <b>{`${translate('ra.title.order_price')}:`}</b>
          <span>{` ${itemsPrice / 100} MDL (${getOriginalPrice(orderItems)} MDL)`}</span>
        </div> */}
        {/* <div>
          <b>{`${translate('ra.title.delivery_price')}:`}</b>
          <span>{` ${deliveryPrice / 100} MDL (${deliveryDetails.price / 100} MDL)`}</span>
        </div> */}
        <div>
          <b>{`${translate('ra.title.total_price')}:`}</b>
          <span>{` ${totalSum / 100} ${getConfig('currency')}`}</span>
        </div>
        {courierCost && (
          <div>
            <b>{`${translate('ra.title.courier_cost')}:`}</b>
            <span>
              {` 
          ${courierCost / 100} 
          ${getConfig('currency')}
          `}
            </span>
          </div>
        )}
      </Card>
      {deliveryStatusLogs && <TimeInfo record={record} />}
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.payment_method')}</div>
        {paymentMethod}
        <br />
        <div style={{ color: `${paymentTextColor}` }}>{paymentStatus}</div>
      </Card>
      <Card className={styles.cardStyle}>
        <div className={styles.cardTitle}>{translate('ra.title.block.restaurant_details')}</div>
        <div>{name ? `${translate('ra.label.name')}: ${name}, ` : ''}</div>
        <div>
          {sourceAddressStreet ? `${translate('ra.label.street')}: ${sourceAddressStreet}, ` : ''}
        </div>
        <div>{phone ? `${translate('ra.label.phone')}: ${phone}, ` : ''}</div>
        <div>{house ? `${translate('ra.label.house')}: ${house}, ` : ''}</div>
        <div>{area ? `${translate('ra.label.area')}: ${area}, ` : ''}</div>
        <div>{sourceLocality ? `${translate('ra.label.locality')}: ${sourceLocality}, ` : ''}</div>
        <div>{sourceCountry ? `${translate('ra.label.country')}: ${sourceCountry}, ` : ''}</div>
      </Card>
      {cancelReasonText && (
        <Card className={styles.cardStyle}>
          <div className={styles.cardTitle}>{translate('ra.title.block.cancel_reason')}</div>
          {cancelReasonText}
        </Card>
      )}
    </div>
  );
};

export default OrderItems;
