import React, { useMemo, useState } from 'react';
import { useTranslate } from 'react-admin';

import { Grid, Button, Input, InputLabel, Autocomplete, TextField } from '@mui/material';

import Card from '../../../../Catalog/components/common/Card';

import formatCurrency from '../../../../utils/formatCurrency';

function AddNewItem({ record, quantityHandler, selectHandler, serialNumberHandler }) {
  const [serialNumberValue, setSerialNumberValue] = useState('');

  const translate = useTranslate();

  const unusedSerialNumbers = useMemo(
    () =>
      record.serialNumbers?.filter(
        (serialNumber) => !serialNumber.isUsed && serialNumber.value !== serialNumberValue?.value,
      ),
    [record.serialNumbers, serialNumberValue],
  );

  const defaultProps = {
    options: unusedSerialNumbers || [],
    getOptionLabel: (option) => option.value,
  };

  if (record.requiresSerialNumber && record.quantity > unusedSerialNumbers.length) {
    if (unusedSerialNumbers.length > 0) {
      record.quantity = unusedSerialNumbers.length;
    } else {
      record.quantity = 1;
    }
  }

  return (
    <Grid item lg={2} md={4} sm={6} xs={6}>
      <Card record={record} toEdit={false} selected={record.selected}>
        <InputLabel
          sx={{
            fontSize: '12px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '12px',
            marginBottom: '12px',
          }}
        >
          {translate('ra.label.quantity')}
          :
          <Input
            sx={{ width: '48px' }}
            type="number"
            step={1}
            min={1}
            value={record.quantity}
            onChange={(e) => {
              quantityHandler(record.id, e.target.value);
            }}
          />
        </InputLabel>
        {record.requiresSerialNumber &&
          Array.from({ length: record?.quantity }, (_, i) => i).map((index) => {
            return (
              <Autocomplete
                {...defaultProps}
                key={index}
                id="auto-complete"
                autoComplete
                includeInputInList
                renderInput={(params) => (
                  <TextField {...params} label="Serial Number" variant="standard" />
                )}
                onChange={(_e, value) => {
                  const valueToSet = value ? { ...value, isUsed: !!value } : null;

                  serialNumberHandler(record.id, valueToSet);

                  setSerialNumberValue(valueToSet);
                }}
              />
            );
          })}
        <InputLabel sx={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
          {translate('ra.label.totalPrice')}
          {' : '}
          <div style={{ fontWeight: 'bold' }}>{formatCurrency(record.price * record.quantity)}</div>
        </InputLabel>
        {record.selected ? (
          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: '12px', width: '100%' }}
            onClick={() => {
              selectHandler(record.id);
            }}
          >
            {translate('ra.action.delete')}
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ marginTop: '12px', width: '100%' }}
            onClick={() => {
              selectHandler(record.id);
            }}
            disabled={
              (record.requiresSerialNumber && !serialNumberValue) ?? record.requiresSerialNumber
            }
          >
            {translate('ra.action.add')}
          </Button>
        )}
      </Card>
    </Grid>
  );
}

export default AddNewItem;
