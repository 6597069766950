import * as React from 'react';
import { Box, ImageList, ImageListItem, Grid } from '@mui/material';

import { useListContext } from 'react-admin';
import Card from '../Card';

const GridList = () => {
  const { isLoading } = useListContext();

  return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};

const times = (nbChildren, fn) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
  const { perPage } = useListContext();

  return (
    <ImageList rowHeight={180} cols={7} sx={{ m: 0 }}>
      {times(perPage, (key) => (
        <ImageListItem key={key}>
          <Box bgcolor="grey.300" height="100%" />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const LoadedGridList = () => {
  const { data } = useListContext();

  if (!data) return null;

  return (
    <Grid container columnSpacing={2} rowSpacing={2} sx={{ m: 0, width: '100%' }}>
      {data.toReversed().map((record) => (
        <Grid item lg={2} md={4} sm={6} xs={6} key={record.id} sx={{ display: 'flex', width: '100%' }}>
          <Card record={record} />
        </Grid>
      ))}
    </Grid>
  );
};

export default GridList;
