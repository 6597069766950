import React from 'react';
import { Edit, required, SelectInput, SimpleForm, TextInput } from 'react-admin';
import PhoneInput from '../../../components/PhoneInput';
import ResourceTitle from '../../../components/ResourceTitle';
import EditDeleteConfirmationButton from '../../../components/EditDeleteConfirmationButton';
import CancelButton from '../../../components/CancelButton';

const validateRequired = required();

const CourierEdit = () => {
  return (
    <Edit title={<ResourceTitle field="firstName" />} actions={<CancelButton />}>
      <SimpleForm toolbar={<EditDeleteConfirmationButton />}>
        <PhoneInput source="phone" />
        <TextInput label="ra.label.first_name" source="firstName" validate={validateRequired} />
        <TextInput label="ra.label.last_name" source="lastName" validate={validateRequired} />
        <SelectInput
          source="status"
          choices={[
            { id: 'ONLINE', name: 'Online' },
            { id: 'OFFLINE', name: 'Offline' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default CourierEdit;
