import { makeStyles } from '@mui/styles';

const translate = 'translate(-50%, -50%)';

const useStyles = makeStyles(() => ({
  icon: {
    backgroundColor: 'white',
    transition: 'all 0.1s ease-in',
    transform: translate,
    '&:hover': {
      backgroundColor: 'white',
      transform: `${translate} scale(1.2)`,
    },
  },
  balloon: {
    padding: 10,
  },
}));

export default useStyles;
