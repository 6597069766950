import * as React from 'react';
import { Box } from '@mui/material';

import { useCreatePath } from 'react-admin';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../../config/connection';
import formatCurrency from '../../../../utils/formatCurrency';

function Card({ record, children, selected = true, toEdit = true }) {
  const { id, name, price, available, cover } = record;
  const createPath = useCreatePath();

  const serverUrl = cover ? `${baseUrl}/s3/uploads/${cover}` : '';

  return (
    <Box
      component={Link}
      key={id}
      to={
        toEdit &&
        createPath({
          resource: 'good',
          id,
          type: 'edit',
        })
      }
      sx={{
        textDecoration: 'none',
        backgroundColor: 'whiteSmoke',
        color: 'black',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          aspectRatio: '1/1',
          filter: available && selected ? '' : 'grayscale(100%)',
        }}
      >
        <img
          style={{ objectFit: 'cover', height: '100%', width: '100%', borderRadius: '5px' }}
          src={serverUrl}
          alt=""
        />
      </Box>

      <Box sx={{ paddingX: '10px', backgroundColor: 'whitesmoke', borderRadius: '5px' }}>
        <div style={{ fontSize: '14px', fontWeight: '600' }}>{name}</div>
        <div style={{ fontSize: '12px', paddingBottom: '5px' }}>{formatCurrency(price)}</div>
        <div style={{ fontSize: '12px', fontWeight: '400', paddingBottom: '5px' }}>{children}</div>
      </Box>
    </Box>
  );
}

export default Card;
