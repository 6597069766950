export default function getAddressObject(addressComponent) {
  const ShouldBeComponent = {
    home: ['street_number'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const address = {
    home: '-',
    street: '-',
    region: '-',
    city: '-',
    country: '-',
  };
  Object.keys(ShouldBeComponent).forEach((shouldBe) => {
    addressComponent.forEach((component) => {
      if (component.types.includes('political')) {
        component.types.splice(component.types.indexOf('political'), 1);
      }
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        address[shouldBe] = component.long_name;
      }
    });
  });

  return address;
}
