import React, { useEffect, useMemo, useState } from 'react';
import {
  AutocompleteInput,
  Create,
  DateInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
  useCreate,
  useRedirect,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { Grid, Typography, Box } from '@mui/material';
import GoogleMap from 'google-map-react';
import { useLoadScript } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config/maps.config';
import ClientPlacemark from '../../../components/maps/ClientPlacemark';
import CancelButton from '../../../components/CancelButton';
import ResourceTitle from '../../../components/ResourceTitle';
import { fetchWithAuthorization } from '../../../utils/fetchWithAuthorization';
import { deliveryAddressKeys, paymentMethodChoices, paymentStatusChoices } from '../../constants';
import AddNewItem from '../common/Card';
import formatCurrency from '../../../utils/formatCurrency';
import { baseUrl } from '../../../config/connection';
import getAddressObject from '../../../utils/mapsLocationParser';
import PlacesAutocomplete from '../common/Autocomplete';

const validateRequired = required();
const libraries = ['places'];

const CreateRequestCreate = (props) => {
  const organizationId = localStorage.getItem('organizationId');
  const translate = useTranslate();
  const [create] = useCreate();
  const redirect = useRedirect();

  const [addedItems, setAddedItems] = useState([]);
  const [loadedMaps, setLoadedMaps] = useState(false);

  const [deliveryAddress, setDeliveryAddress] = useState({ geo: { lat: 0, lng: 0 } });
  const totalSum = useMemo(() => {
    let total = 0;
    addedItems
      .filter((item) => item.selected)
      .forEach((item) => {
        total += item.price * item.quantity;
      });

    return total;
  }, [addedItems]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'en',
  });

  useEffect(() => {
    const filterParam = `filter[0]=organizationId||$eq||${organizationId}`;
    const urlCatalog = `${baseUrl}/admin/good?&${filterParam}`;
    fetchWithAuthorization(urlCatalog)
      .then((results) => results.json())
      .then((data) => {
        const newArray = data
          .filter((item) => item.available)
          .map((item) => {
            return {
              ...item,
              quantity: 1,
              selected: false,
            };
          });
        setAddedItems(newArray);
      });
    const urlRestaurant = `${baseUrl}/admin/restaurant?&${filterParam}`;
    fetchWithAuthorization(urlRestaurant)
      .then((results) => results.json())
      .then((data) => {
        if (data.length > 0) {
          setDeliveryAddress({
            geo: data[0].geo,
          });
        }
      })
      .finally(() => setLoadedMaps(true));
  }, [organizationId]);

  const selectHandler = (id) => {
    const newObject = [...addedItems];
    const item = newObject.find((i) => i.id === id);
    item.selected = !item.selected;
    setAddedItems(newObject);
  };

  const quantityHandler = (id, value) => {
    if (value < 1) return;
    const newObject = [...addedItems];
    newObject.find((item) => item.id === id).quantity = value;
    setAddedItems(newObject);
  };

  const serialNumberHandler = (id, value) => {
    const newObject = [...addedItems];

    const itemSerialNumbers = newObject.find((item) => item.id === id).serialNumbers;
    const filteredSerialNumbers = itemSerialNumbers.filter(
      (serialNumber) => serialNumber.value !== value.value,
    );

    const serialNumbers = [...filteredSerialNumbers, value];

    newObject.find((item) => item.id === id).serialNumbers = serialNumbers;

    setAddedItems(newObject);
  };

  const mapDetailsSetter = (results, location) => {
    const { home, street, city, country, region } = getAddressObject(results.address_components);

    setDeliveryAddress({
      ...deliveryAddress,
      country,
      area: region,
      locality: city,
      street,
      streetNumber: home,
      buildingNumber: home,
      geo: location,
      formattedValue: `${home}, ${street}, ${city}, ${region}, ${country}`,
    });
  };
  const validateGeo = () => {
    const errors = {};
    if (Object.keys(deliveryAddress).length === 1) {
      errors.geo = {
        message: 'ra.validation.required',
      };
    }

    return errors;
  };
  const handleNewDeliveryAddressLatLng = (lat, lng) => {
    const location = { lat, lng };
    getGeocode({ location }).then((results) => {
      mapDetailsSetter(results[0], location);
    });
  };

  const handleAddressClickedAutocomplete = (address) => {
    getGeocode({ address }).then((results) => {
      const location = getLatLng(results[0]);
      mapDetailsSetter(results[0], location);
    });
  };

  const onSubmit = (data) => {
    const deliveryRequest = {
      ...data,
      totalSum,
      organizationId,
      deliveryAddress: {
        ...data.deliveryAddress,
        ...deliveryAddress,
      },
      products: addedItems
        .filter((item) => item.selected)
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            basePrice: item.price,
            totalPrice: item.price * item.quantity,
            serialNumbers: item.serialNumbers,
          };
        }),
    };

    create(
      'delivery/request-delivery',
      { data: deliveryRequest },
      {
        onSuccess: () => {
          redirect('list', '/delivery/request-delivery');
        },
      },
    );
  };

  return (
    <Create
      {...props}
      title={<ResourceTitle field="name" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm
        redirect="list"
        validate={validateGeo}
        onSubmit={onSubmit}
        toolbar={
          <Toolbar>
            <SaveButton />
            <Typography sx={{ marginLeft: '12px', fontWeight: 'bold' }}>
              {translate('ra.label.totalSum')}
              {': '}
              {formatCurrency(totalSum)}
            </Typography>
          </Toolbar>
        }
      >
        <Grid container sx={{ border: 'solid 1px #EFEFEF' }} columnSpacing={2}>
          <Grid item>
            <TextInput label="ra.label.order_id" source="orderId" validate={validateRequired} />
          </Grid>

          {['firstName', 'lastName'].map((part) => (
            <Grid item>
              <TextInput
                key={part}
                label={`${translate('ra.label.customer')} - ${translate(`ra.label.${part}`)}`}
                source={`customer.${part}`}
                validate={validateRequired}
              />
            </Grid>
          ))}
          <Grid item>
            <TextInput
              label={`${translate('ra.label.customer')} - ${translate('ra.label.phone')}`}
              source="customer.phoneNumber"
              validate={validateRequired}
            />
          </Grid>
          <Grid item>
            <ReferenceInput
              source="restaurantId"
              reference="restaurant"
              filter={{ organizationId }}
            >
              <AutocompleteInput
                label="ra.label.sourceAddress"
                optionText="name"
                sx={{ width: '218px' }}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Grid container sx={{ border: 'solid 1px #EFEFEF' }} columnSpacing={2}>
          <Grid item>
            <DateInput label="ra.label.date" source="date" sx={{ width: '218px' }} />
          </Grid>
          <Grid item>
            <SelectInput
              label="ra.label.paymentMethod"
              source="paymentMethod"
              choices={paymentMethodChoices}
              validate={validateRequired}
              sx={{ width: '218px' }}
            />
          </Grid>
          <Grid item>
            <SelectInput
              label="ra.label.paymentStatus"
              source="paymentStatus"
              choices={paymentStatusChoices}
              validate={validateRequired}
              sx={{ width: '218px' }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            height: '500px',
            width: '100%',
          }}
        >
          {loadedMaps && isLoaded && (
            <>
              <PlacesAutocomplete
                onAddressSelect={(address) => handleAddressClickedAutocomplete(address)}
                addressActive={deliveryAddress.formattedValue}
              />
              <GoogleMap
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                defaultCenter={deliveryAddress.geo}
                center={deliveryAddress.geo}
                defaultZoom={13}
                onClick={({ lat, lng }) => {
                  handleNewDeliveryAddressLatLng(lat, lng);
                }}
              >
                <ClientPlacemark lat={deliveryAddress.geo.lat} lng={deliveryAddress.geo.lng} />
              </GoogleMap>
            </>
          )}
        </Box>
        <Grid sx={{ border: 'solid 1px #EFEFEF' }} container columnSpacing={2}>
          {deliveryAddressKeys.map((part) => (
            <Grid item xs={12} md={4}>
              <TextInput
                sx={{ width: '100%' }}
                key={part}
                label={`${translate('ra.label.deliveryAddress')} - ${translate(
                  `ra.label.${part}`,
                )}`}
                source={`deliveryAddress.${part}`}
              />
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          sx={{ width: '100%', marginBottom: '60px' }}
        >
          {addedItems.toReversed().map((item) => {
            return (
              <AddNewItem
                key={item.id}
                record={item}
                quantityHandler={quantityHandler}
                selectHandler={selectHandler}
                serialNumberHandler={serialNumberHandler}
              />
            );
          })}
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default CreateRequestCreate;
