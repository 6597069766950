import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import FileItem from './FileItem';

const FileList = (props) => {
  const { files, deleteFile, disabled, classes, apiRoot, fileCoverImg } = props;
  const [gallery, setGallery] = useState(false);
  const [galleryImg, setGalleryImg] = useState(false);

  const showBiggerImg = (url) => {
    setGallery(!gallery);
    setGalleryImg(url);
  };

  // const generateUniqueKey = (index) => {
  //   return new Date().getMilliseconds() * index;
  // };

  if (!files?.length) {
    return <div className={classes.empty}> No Files</div>;
  }

  return (
    <>
      <ul className={classes.addedFiles}>
        {/* {files?.map((file, i) => (        ))} */}
        <li className={classes.fileItem}>
          <FileItem
            apiRoot={apiRoot}
            file={files}
            fileCoverImg={fileCoverImg}
            showBiggerImg={(url) => showBiggerImg(url)}
            classes={classes}
            disabled={disabled}
            deleteFile={deleteFile}
          />
        </li>
      </ul>
      {gallery && <Lightbox mainSrc={galleryImg} onCloseRequest={() => setGallery(false)} />}
    </>
  );
};

export default FileList;
