import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import { LocalGroceryStoreRounded } from '@mui/icons-material';
import useAnchor from '../../../hooks/useAnchor';
import useStyles from './styles';

const SupplierPlacemark = ({ order }) => {
  const { anchorEl, open, handleClose, handleOpen } = useAnchor();
  const styles = useStyles();

  const { sourceAddress } = order;

  return (
    <div>
      <IconButton onClick={handleOpen} className={styles.icon}>
        <LocalGroceryStoreRounded fontSize="large" color="secondary" />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className={styles.balloon}>
          <Typography variant="h6">{sourceAddress?.name}</Typography>
        </div>
      </Popover>
    </div>
  );
};

export default SupplierPlacemark;
