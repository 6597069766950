import React from 'react';
import { CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { USER_ROLES } from '../config/user.config';
import users from '../Users';
import delivery from '../Delivery';
import courier from '../Courier';
import deliverySettings from '../DeliverySettings';
import courierPayoutPolicy from '../CourierPayoutPolicy';
import webhook from '../WebHooks';
import Reports from '../Reports';
import organization from '../Organization';
import restaurant from '../Restaurant';
import Organization from '../components/Layout/Organization';
import apiKeys from '../ApiKeys';
import courierAutomationSettings from '../CourierAutomationSettings';
import deliveryRequest from '../DeliveryRequest';
import catalog from '../Catalog';

// custom routes don't work as intended if they are separated in a component

const renderRoutes = (userConfig) => {
  const isAdmin = userConfig?.role === USER_ROLES.ADMIN;
  const isUser = userConfig?.role === USER_ROLES.USER;

  const hasShowAccess = userConfig.rolePermissions?.includes('view');
  const hasCreateAccess = userConfig.rolePermissions?.includes('create');
  const hasEditAccess = userConfig.rolePermissions?.includes('edit');
  const hasDeleteAccess = userConfig.rolePermissions?.includes('delete');

  const hasAccessToCreateApiKeys = userConfig.rolePermissions?.includes('create_api_key');
  const hasAccessToViewApiKeys = userConfig.rolePermissions?.includes('view_api_key');
  const hasAccessToDeleteApiKeys = userConfig.rolePermissions?.includes('delete_api_key');

  const accesProps = {
    list: hasShowAccess || hasCreateAccess || hasEditAccess || hasDeleteAccess,
    show: hasShowAccess || hasCreateAccess || hasEditAccess || hasDeleteAccess,
    create: hasCreateAccess,
    edit: hasEditAccess,
  };

  const resourceAccess = (resource) => {
    return {
      list: accesProps.list ? resource.list : false,
      edit: accesProps.edit ? resource.edit : false,
      show: accesProps.show ? resource.show : false,
      create: accesProps.create ? resource.create : false,
    };
  };

  const apiAccessProps = {
    list: hasAccessToViewApiKeys || hasAccessToCreateApiKeys || hasAccessToDeleteApiKeys,
    create: hasAccessToCreateApiKeys,
    edit: hasAccessToCreateApiKeys,
  };

  const apiAccess = () => {
    return {
      list: apiAccessProps.list ? apiKeys.list : false,
      edit: apiAccessProps.edit ? apiKeys.edit : false,
    };
  };

  if (isUser) {
    return [
      <Resource key="delivery/today" name="delivery/today" {...resourceAccess(delivery)} />,
      <Resource key="delivery/history" name="delivery/history" {...resourceAccess(delivery)} />,
      <Resource
        key="delivery/request-delivery"
        name="delivery/request-delivery"
        {...resourceAccess(deliveryRequest)}
      />,
      <Resource key="good" name="good" {...resourceAccess(catalog)} />,
      <Resource
        key="deliverySettings"
        name="deliverySettings"
        {...resourceAccess(deliverySettings)}
      />,
      <Resource key="courier" name="courier" {...resourceAccess(courier)} />,
      <Resource key="policy" name="policy" {...resourceAccess(courierPayoutPolicy)} />,
      <Resource
        key="couriers-auto-settings"
        name="courier-auto-settings"
        {...resourceAccess(courierAutomationSettings)}
      />,
      <Resource key="restaurant" name="restaurant" {...restaurant} />,
      <Resource key="webhook" name="webhook" {...resourceAccess(webhook)} />,
      apiAccessProps.list && <Resource key="api-access" name="api-access" {...apiAccess()} />,
      <CustomRoutes>
        <Route exact path="/report" element={<Reports />} />
      </CustomRoutes>,
      <CustomRoutes key="organization-menu" noLayout>
        <Route path="/organization-menu" exact element={<Organization />} />
      </CustomRoutes>,
    ];
  }

  if (isAdmin) {
    return [
      <Resource key="user" name="user" {...users} />,
      <Resource key="organization" name="organization" {...organization} />,
      <CustomRoutes key="organization-menu" noLayout>
        <Route path="/organization-menu" exact element={<Organization />} />
      </CustomRoutes>,
      <CustomRoutes>
        <Route exact path="/report" element={<Reports />} />
      </CustomRoutes>,
    ];
  }
};

export default renderRoutes;
