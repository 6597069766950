import React from 'react';
import { List, Datagrid, TextField, ReferenceField, FunctionField } from 'react-admin';
import OrderedAtField from '../../../components/OrderedAtField';
import PriceField from '../../../components/PriceField';
import { CURRENCY_NAME } from '../../../config/currency.config';

const CreateRequestList = () => {
  const organizationId = localStorage.getItem('organizationId');

  return (
    <List filter={{ organizationId }}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="ra.label.id" source="orderId" />
        <OrderedAtField label="ra.label.uppercase.ordered_at" />
        <PriceField
          label="ra.label.uppercase.price"
          source="totalSum"
          parse={(v) => v / 100}
          priceLabel={CURRENCY_NAME}
        />
        <ReferenceField
          label="ra.label.created_by"
          source="createdBy"
          reference="user"
          link={false}
        >
          <FunctionField render={(record) => record.username} />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default CreateRequestList;
