import React from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

import randomString from 'crypto-random-string';
import CancelButton from '../../../components/CancelButton';
import ResourceTitle from '../../../components/ResourceTitle';
import S3FileInput from '../../../components/S3FileUploader/Input';
import { baseUrl } from '../../../config/connection';

const validateRequired = required();

const CatalogCreate = (props) => {
  const organizationId = localStorage.getItem('organizationId');
  const catalogDefaultValues = () => ({ organizationId });

  return (
    <Create
      {...props}
      title={<ResourceTitle field="name" />}
      actions={<CancelButton />}
      redirect="list"
    >
      <SimpleForm redirect="list" defaultValues={catalogDefaultValues()}>
        <TextInput label="ra.label.name" source="name" validate={validateRequired} />
        <TextInput
          label="ra.label.description"
          source="description"
          multiline
          validate={validateRequired}
        />
        <NumberInput
          label="ra.label.price"
          source="price"
          validate={validateRequired}
          parse={(v) => v * 100 || ''}
          format={(v) => v / 100 || ''}
          step={0.01}
          min={0}
        />
        <BooleanInput label="ra.label.available" source="available" />
        <BooleanInput label="ra.label.requiresSerialNumber" source="requiresSerialNumber" />
        <ArrayInput source="serialNumbers">
          <SimpleFormIterator inline>
            <TextInput source="value" helperText={false} />
            <BooleanInput
              label="ra.label.isUsed"
              source="isUsed"
              defaultValue={false}
              sx={{ display: 'none' }}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <S3FileInput
          apiRoot={baseUrl}
          source="cover"
          validate={validateRequired}
          uploadOptions={{
            signingUrl: `${baseUrl}/s3/sign`,
            s3path: `dely/${randomString({ length: 10, type: 'alphanumeric' })}`,
            multiple: false,
            accept: 'image/*',
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default CatalogCreate;
